import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  margin: 0 auto;
  width: 94vw;
  max-width: 1800px;

  @media (max-width: 840px) {
    width: 90vw;
  }
`

const Container = props => {
  return <Wrapper className="wrapper">{props.children}</Wrapper>
}

export default Container
